import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import api from '@/lib/api'
import { getAnyAccessToken } from '@/lib/getAccessToken'
import {
  TruckVisit,
  TruckVisitDetail,
  TruckVisitDetailResponse,
  VisitHistoryResponse,
} from './types'

export const historyVisitQueryKey = ['visitHistory']
export const currentVisitQueryKey = ['currentVisit']
export const homeCurrentVisitQueryKey = ['homeCurrentVisit']

const orderDetailResponse = (data: TruckVisitDetailResponse) => ({
  ...data.truckVisit,
  stages: data.truckVisit.stages.sort((a, b) => a.sortOrder - b.sortOrder),
  ...(data.truckVisit.routePlan
    ? {
        routePlan: {
          ...data.truckVisit.routePlan,
          containers: data.truckVisit.routePlan.containers.sort(
            (a, b) => a.sortOrder - b.sortOrder,
          ),
        },
      }
    : {}),
})

export const useGetVisitHistory = (options?: UseQueryOptions) => {
  const accessToken = getAnyAccessToken()

  return useQuery<VisitHistoryResponse, unknown, TruckVisit[], any>({
    queryKey: historyVisitQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/history`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: VisitHistoryResponse) => data.truckVisits,
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && options.enabled
        : !!accessToken,
  })
}

export const useGetVisit = (
  options?: UseQueryOptions,
  truckVisitId?: string,
) => {
  const accessToken = getAnyAccessToken()

  return useQuery<TruckVisitDetailResponse, unknown, TruckVisitDetail, any>({
    queryKey: ['visit', truckVisitId],
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/${truckVisitId}`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: TruckVisitDetailResponse) => orderDetailResponse(data),
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && !!truckVisitId && options.enabled
        : !!accessToken && !!truckVisitId,
  })
}

export const useGetCurrentVisit = (options?: UseQueryOptions) => {
  const accessToken = getAnyAccessToken()

  return useQuery<TruckVisitDetailResponse, unknown, TruckVisitDetail, any>({
    queryKey: currentVisitQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/active`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: TruckVisitDetailResponse) => orderDetailResponse(data),
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && options.enabled
        : !!accessToken,
  })
}
